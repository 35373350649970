import React, { useContext, useEffect, useRef, useState } from "react";
import { createBrowserHistory as createHistory } from "history";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import LandingPage from "../landingPage/LandingPage";
import EmptyLayout from "../layouts/EmptyLayout";
import Preview from "../landingPage/Preview";

const history = createHistory();
const $ = window.$;

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Layout screenProps={ScreenProps} {...rest}>
    <Component />
  </Layout>
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  authentication,
  ...rest
}) =>
  authentication === true ? (
    <Layout screenProps={ScreenProps}>
      <Component authRoute={true} />
    </Layout>
  ) : (
    <Navigate to={{ pathname: "/" }} />
  );

const App = () => {
  let userId = localStorage.getItem("userId");
  let accessToken = localStorage.getItem("accessToken");
  let userLoginStatus = localStorage.getItem("userLoginStatus");

  const [loading, setLoading] = useState(true);

  const [configLoading, setConfigLoading] = useState(true);

  const [authentication, setAuthntication] = useState(
    userId && accessToken && userLoginStatus == "true" ? true : false
  );

  history.listen((location, action) => {
    userId = localStorage.getItem("userId");
    accessToken = localStorage.getItem("accessToken");
    userLoginStatus = localStorage.getItem("userLoginStatus");
    setLoading(true);
    setAuthntication(
      userId && accessToken && userLoginStatus == "true" ? true : false
    );
    document.body.scrollTop = 0;
  });

  return (
    <>
      {/* <Helmet>
				<title>{configuration.get("configData.site_name")}</title>
				<link
					rel="icon"
					type="image/png"
					href={configuration.get("configData.site_icon")}
				/>
				<script src={configuration.get("configData.head_scripts")}></script>
			</Helmet> */}
      <BrowserRouter>
        <Routes>
          <Route
            path={"/"}
            element={<AppRoute component={LandingPage} layout={EmptyLayout} />}
          />
          <Route
            path={"/preview"}
            element={
              <AppRoute component={Preview} layout={EmptyLayout} />
            }
          />
          {/* <Route
            path={"/pns-search"}
            element={
              <AppRoute component={PnsSearchIndex} layout={EmptyIndex} />
            }
          />
          <Route
            path={"/name/:domain/details"}
            element={
              <AppRoute component={DomainDetailsIndex} layout={EmptyIndex} />
            }
          />
          <Route
            path={"/domain-register"}
            element={
              <AppRoute component={DomainRegitserIndex} layout={EmptyIndex} />
            }
          />
          <Route
            path={"/faq"}
            element={<AppRoute component={FaqIndex} layout={EmptyIndex} />}
          />
          <Route
            path={"/about"}
            element={<AppRoute component={AboutUs} layout={EmptyIndex} />}
          />
          <Route
            path={"/address/:address"}
            element={
              <AppRoute component={MyAccountIndex} layout={EmptyIndex} />
            }
          />
          <Route
            path={"/search/:domain"}
            element={
              <AppRoute component={DomainSearch} layout={EmptyIndex} />
            }
          />
          <Route
            path={"/favourites"}
            element={
              <AppRoute component={FavouritesIndex} layout={EmptyIndex} />
            }
          />
          <Route
            path={"/name/:domain/register"}
            element={
              <AppRoute component={AccountRegister} layout={EmptyIndex} />
            }
          /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
