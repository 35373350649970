import './App.css';
import Base from "./components/Base";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import store from "./store";
import { Provider } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import { WagmiConfig, createClient, chain } from "wagmi";
import { ConnectKitProvider, ConnectKitButton, getDefaultClient } from "connectkit";
import { Buffer } from "buffer";

const alchemyId = process.env.ALCHEMY_ID;
const chains = [chain.polygonMumbai];
if (!window.Buffer) window.Buffer = Buffer;
const client = createClient({
  ...getDefaultClient({
    appName: "Your App Name",
    alchemyId,
    chains,
  }),
});

function App() {


  return (
    <Provider store={store}>
      <ToastContainer />
      <WagmiConfig client={client}>
        <ConnectKitProvider>
          <Base />
        </ConnectKitProvider>
      </WagmiConfig>
    </Provider>
  );
}

export default App;
