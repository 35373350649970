import { DYNAMIC_NFT_CODE_GENERATION } from "../actions/ActionConstant";

const initialState = {
  dynamicProfile: {
    data: "",
  },
};

const NFTReducer = (state = initialState, action) => {
  switch (action.type) {
    case DYNAMIC_NFT_CODE_GENERATION:
      return {
        ...state,
        dynamicProfile: {
          data: action.data,
        }
      };
    default:
      return state;
  }
}

export default NFTReducer;