import React, { useState } from 'react';
import DynamicNFTBuilder from "../DynamicNFT/DynamicNFTBuilder";
import { useNavigate } from 'react-router-dom';
import { dynamicNftCodeGeneration } from '../../store/actions/NFTAction';
import { connect } from 'react-redux';

const LandingPage = (props) => {
  const navigate = useNavigate();

  const onProfileGeneration = fullCode => {
    props.dispatch(dynamicNftCodeGeneration(fullCode));
    navigate("/preview");
  }

  return (
    <>
      <DynamicNFTBuilder onProfileGeneration={onProfileGeneration} />
    </>
  );
}

const mapStateToPros = (state) => ({
  // forgotPassword: state.users.forgotPassword,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(LandingPage);