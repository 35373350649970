import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";

const Preview = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!props.dynamicProfile.data) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <iframe srcDoc={props.dynamicProfile.data} style={{ width: "100%", height: "calc(100vh - 5px)" }} />
      <Link to={"/"} className="back-btn">
        <img src="/images/back.png" />
      </Link>
    </>
  );
}

const mapStateToPros = (state) => ({
  dynamicProfile: state.nft.dynamicProfile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Preview);