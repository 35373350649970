import React, { useState, useEffect, forwardRef, useImperativeHandle, } from "react";
import grapesjs from 'grapesjs';
import "grapesjs/dist/css/grapes.min.css";
import './NewGrapes.css';
import customCodePlugin from 'grapesjs-custom-code';
import plugin from 'grapesjs-script-editor';
import { getDefaultTemplate } from './DefaultTemplate';
import 'grapesjs-blocks-basic/dist/grapesjs-blocks-basic.min.js';
// import * as htmlToImage from 'html-to-image';
// import { data } from "jquery";
let value = 0;

let editor = null;

const DynamicNFTBuilder = forwardRef((props, ref) => {
  let blockManager = null;

  const [tool, setTool] = useState("component");
  const [activeTab, setActiveTab] = useState("design");
  const [scripts, setScripts] = useState("");

  useImperativeHandle(ref, () => ({
    generateCode() {
      let html = editor.getHtml();
      let css = editor.getCss();
      let fullCode = `<html><head><style>${css}</style></head>${html} ${scripts}</html>`;
      return fullCode;
    },
    // async generateScreenShot() {
    //   editor.select(null);
    //   let iframe = document.getElementsByClassName("gjs-frame");
    //   const iwindow = iframe[0].contentWindow;
    //   const iDocument = iwindow.document;
    //   const data = await htmlToImage.toPng(iDocument.getElementsByTagName('body')[0]);
    //   return data;
    // }
  }));

  const codeGeneration = () => {
    let html = editor.getHtml();
    let css = editor.getCss();
    let fullCode = `<html><head><link href="https://fonts.googleapis.com/css?family=Poppins&display=swap" rel="stylesheet"><style>${css}</style></head>${html} ${scripts}</html>`;
    props.onProfileGeneration(fullCode);
  }

  const setComponents = () => {
    blockManager = editor.Blocks;
    const blocks = blockManager.getAll();
    // console.log(JSON.stringify(blocks));
    // editor.plugins.add('grapesjs-custom-code', customCodePlugin);
    // editor.BlockManager.add('my-block', {
    //   label: 'Custom',
    //   // category: 'Basic',
    //   content: {
    //     type: 'custom-code',
    //   },
    // });
    // blockManager.add('h1-block', {
    //   label: 'Heading',
    //   content: '<h1>Put your title here</h1>',
    //   category: 'Basic',
    //   attributes: {
    //     title: 'Insert h1 block'
    //   }
    // });
  }

  useEffect(() => {
    document.getElementById('blocks').innerHTML = "";
    document.getElementById('gjs').innerHTML = getDefaultTemplate();
    document.getElementById('stylesContainer').innerHTML = "";
    editor = grapesjs.init({
      container: '#gjs',
      fromElement: true,
      height: 'calc(100vh - 50px)',
      width: '100%',
      storageManager: false,
      panels: { defaults: [] },
      plugins: [customCodePlugin, plugin, "gjs-blocks-basic"],
      pluginsOpts: {
        'gjs-blocks-basic': {
          category: "Columns",
          blocks: ['column1', 'column2', 'column3', 'column3-7']
        }
      },
      blockManager: {
        custom: true,
        appendTo: '#blocks',
        blocks: [
          {
            id: 'template', // id is mandatory
            label: '<b><img src="/images/dynamic/default-template.png" /></b>',
            // label: '<b>Default</b>', // You can use HTML/SVG inside labels
            // attributes: { class: 'gjs-block-section' },
            // content: `<section>
            //   <h1>This is a simple title</h1>
            //   <div>This is just a Lorem text: Lorem ipsum dolor sit amet</div>
            // </section>`,
            content: getDefaultTemplate(),
          },
          {
            id: 'text',
            label: '<b><img src="/images/dynamic/Ab.svg"/></>',
            content: '<div data-gjs-type="text">Insert your text here</div>',
          }, {
            id: 'image',
            label: '<b><img src="/images/dynamic/Image.svg" /></b>',
            select: true,
            content: { type: 'image' },
            activate: true,
          },
          {
            id: 'link',
            label: '<b><img src="/images/dynamic/Link.png" /></b>',
            // media: svgLink,
            activate: true,
            content: {
              type: 'link',
              content: 'Insert your link here',
              style: { color: '#d983a6' },
              attributes: {
                target: "_blank",
                href: "#"
              }
            }
          },
          {
            id: 'custom-code',
            label: '<b><img src="/images/dynamic/Code.svg" /></b>',
            // media: svgLink,
            activate: true,
            content: {
              type: 'custom-code',
            }
          },
        ]
      },
      traitManager: {
        appendTo: '#stylesContainerTrait',
      },
      styleManager: {
        appendTo: '#stylesContainer',
        sectors: [
          {
            name: "Properties",
            open: true,
            buildProps: [
              'display',
              'width',
              'height',
              'font-family',
              'font-size',
              'font-weight',
              'font-style',
              'text-decoration',
              'line-height',
              'text-align',
              'position',
              'top',
              'left',
              'bottom',
              'right',
              'color',
              'background-color',
              'background-image',
              'padding',
              'margin',
              'border',
              'border-radius',
            ],
            properties: [
              {
                id: "text-decoration",
                name: "Text Decoration",
                type: "select",
                options: [
                  { value: 'none', name: 'None' },
                  { value: 'overline', name: 'Over Line' },
                  { value: 'underline', name: 'Under Line' },
                  { value: 'line-through', name: 'Line Through' },
                ]
              },
              {
                id: "font-style",
                name: "Font Style",
                type: "select",
                options: [
                  { value: 'normal', name: 'Normal' },
                  { value: 'italic', name: 'Italic' },
                  { value: 'oblique', name: 'Oblique' },
                ]
              }
            ]
          },
        ],
      },
    });
    setComponents();
  }, []);

  const showCode = () => {
    setActiveTab("code");
    editor.runCommand('open-code');
  }

  const showPreview = () => {
    setActiveTab("preview");
    editor.runCommand('preview');
  }

  const showDesign = () => {
    setActiveTab("design");
    editor.stopCommand('open-code');
    editor.stopCommand('preview');
  }

  return (
    <div className="grapes-container">
      <div className="editor">
        <div className="editor-header">
          <div className="header-title">Create Your Dynamic NFT</div>
          <div className="header-buttons">
            <button
              onClick={() => showDesign()}
              className={activeTab === "design" ? "active-button" : ""}>Design</button>
            {/* <button
              onClick={() => showPreview()}
              className={activeTab === "preview" ? "active-button" : ""}>Preview</button> */}
            <button
              onClick={() => codeGeneration()}
              className={activeTab === "code" ? "active-button" : ""}>Generate Profile</button>
          </div>
        </div>
        <div className="editor-body-sec">
          <div id="gjs"></div>
        </div>
      </div>
      <div className={`toolbar ${activeTab === "preview" ? "hide-tool" : ""}`}>
        <div className="toolbar-header">
          <div className="toolbar-title">Tool Box</div>
        </div>
        <div className="toolbar-body">
          <div className="toolbar-buttons">
            <button
              className={`${tool === "component" ? "active-button" : ""}`}
              onClick={() => setTool("component")}>Components</button>
            <button
              className={`${tool === "style" ? "active-button" : ""}`}
              onClick={() => setTool("style")}>Properties</button>
          </div>
          <div className={`toolbar-sec ${tool != "component" ? "hide" : ""}`}>
            <h5>Add New Item</h5>
            <p>Drag and drop one of these to create your Dynamic NFT</p>
            <div id="blocks"></div>
            <h5 className="mt-3">Scripts</h5>
            <div>
              <textarea
                className="form-control script-area"
                value={scripts}
                onChange={e => setScripts(e.target.value)}
                rows="5" />
            </div>
          </div>
          <div className={`toolbar-sec ${tool != "style" ? "hide" : ""}`}>
            <h5>Properties</h5>
            <p>Change the properties here</p>
            <div className="stylesContainer">
              <div id="stylesContainerTrait"></div>
              <div id="stylesContainer"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default React.memo(DynamicNFTBuilder);