import { getBanner, getFacebook, getInstagram, getProfileImage, getTelegram, getTwitter } from './SocialIcons';

export const getDefaultTemplate = () => {
  return `
  <div class="single-page-sec">
  <div class="single-page-post-sec">
      <div class="single-page-post-img-sec">
          <img src="`+ getBanner() + `" class="single-page-post-img">
      </div>
  
  </div>
  <div class="single-post-profile">
  <div class="profile-img">
      <img src="`+ getProfileImage() + `" alt="">
  </div>
  <div class="profile-content">
  <h3>Mutant Ape</h3>
  <div class="profile-details">
      <h4>Gender : Male</h4>
      <h4> Email : yourmail@gmail.com</h4>
  </div>
  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fugit dolore qui veniam, omnis veritatis voluptates! Alias tenetur repellendus minus incidunt quisquam repellat? Esse assumenda alias officia facilis accusamus quidem? Enim?</p>
  <div class="socila-links">
      <ul>
          <li><a href=""><img src="`+ getFacebook() + `" alt=""></a></li>
          <li><a href=""><img src="`+ getInstagram() + `" alt=""></a></li>
          <li><a href=""><img src="`+ getTelegram() + `" alt=""></a></li>
          <li><a href=""><img src="`+ getTwitter() + `" alt=""></a></li>
      </ul>
  </div>
  </div>
  
  </div>
  <style>
    body {
        margin: 0;
        padding: 0;
        font-family: 'Poppins', sans-serif !important;
    }


    .single-page-post-img {
        height: 30vh;
        width: 100%;
        object-fit: contain;
        background-color: #000;
    }
    .profile-content h3{
        color: #000;
    }
    .profile-img img{
        position: relative;
    min-height: 170px;
    min-width: 170px;
    max-height: 170px;
    max-width: 170px;
    display: flex;
    border-radius: 50%;
    border: 3px solid #f1ecfe;
    object-fit: cover;
    margin: -2em auto auto;
    }
    .profile-content{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        max-width: 950px;
        margin: 0 auto;
    }
    .profile-content p{
        text-align: center;
        margin: 0;
        line-height: 1.8;
        color: #898989;
        font-size: 1em;
        font-weight: 400;
    }
    .profile-details{
        display: flex;
        gap: 5em;
        padding-left: 6em;
    }
    .socila-links ul li a img{
        width: 2em;
    }
    .socila-links ul li{
        list-style: none;
    }
    .socila-links ul{
        display: flex;
        align-items: center;
        gap: 2em;
        margin: 1.5em 0 0;
        padding: 0;
    }
    .profile-details h4{
        margin: 0 0 0.8em;
        font-weight: 500;
        color: #000;

    }

    

    body::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #F5F5F5;
    }

    body::-webkit-scrollbar {
        width: 6px;
        background-color: #F5F5F5;
    }

    body::-webkit-scrollbar-thumb {
        background-color: #00C75E;
    }

    /*Responsive CSS*/
    @media (max-width: 1199.98px) {}
    @media (max-width: 991.98px) {}
    @media (max-width: 767.98px) {
        .profile-details{
            padding: 0;
            gap: 0;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .socila-links ul{
            margin: 1.5em 0;
        }
     
        .single-page-action-btn-sec {
            padding: 0 1em;
        }
    }

    </style>
    </div>
    `;
}