import React, { useState } from "react";

const EmptyLayout = (props) => {
  return (
    <>
      <div className="main-wrapper">
        {props.children}
      </div>
    </>
  );
};

export default EmptyLayout;
